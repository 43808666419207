<template>
  <footer class="mt-8 bg-o_purple-100 border-b border-gray-200">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
      <p class="my-1 text-center text-sm text-white" v-for="info in footers" :key="info">{{ info }}</p>
      <p class="my-1 text-center text-sm text-white "><a target="_blank" class="inline hover:underline transition:ease-out duration-300" href="https://www.ricehouse-blockchain-record-en.ntu.asia//">English Version-Mobile Page</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterR',
  data: function () {
    return {
      footers: [
        '行政院農業部 計畫補助',
        '國立台灣大學生物環境系統工程學系 維運',
        '106 台北市大安區羅斯福路四段1號',
        '版本: 1.3.0 更新於 2024/12'
      ]
    }
  }
}
</script>

<style scoped>

</style>
